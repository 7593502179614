<template>
  <div id="__clou-guest-layout" class="clou-guest-layout">
    <v-container class="__guest-layout-container" fluid>
      <v-row class="__guest-content-wrapper" no-gutters>
        <v-col class="__guest-left-container hidden-sm-and-down" cols="12" sm="5" md="7" lg="8">
          <v-card v-if="ad" class="ad-container" :href="ad.linkUrl" target="_blank" @click="adClickTrack">
            <div class="ad-tag">
              Ad
            </div>
            <v-img :src="ad.imageUrl" height="472px" width="390px" />
          </v-card>
          <div class="__guest-left-container-bottom-street-wireframe" />
        </v-col>
        <v-col class="__main-wrapper" cols="12" sm="12" md="5" lg="4">
          <LayoutsGuestHeader />
          <main>
            <v-row>
              <v-col cols="12" sm="10" md="12" lg="12">
                <NuxtPage />
              </v-col>
            </v-row>
          </main>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup lang="ts">
import {post} from '~/api';

const ad = ref<{slug: string; imageUrl: string; linkUrl: string} | null>(null);
const {$config} = useNuxtApp();

onMounted(() => {
  getAd();
});

async function getAd() {
  const response: any = await $fetch(`https://search.newhomeshub.co.nz/collections/${$config.public.appEnv}_listings/documents/search?q=*&query_by=adDisplayLocations&include_fields=id,+name,+slug,+listingTypes,+heroImageUrl,+bedrooms,+bathrooms,+price,+garageSpaces,+floorArea,+landArea,+company,+address,+addressOverride,+isSold,+category,+adDestinationUrl,+adOtherImageUrls&filter_by=status:%3DACTIVE+%26%26+category:%3DAd+%26%26+adDisplayLocations:%3Dnh_hub_login&page=1&sort_by=isSold:asc,priority:desc&per_page=1&x-typesense-api-key=${$config.public.searchApiKey}`);
  if (response && response.hits && response.hits.length) {
    const randomIndex = Math.floor(Math.random() * response.hits.length);
    const selectedAd = response.hits[randomIndex].document;
    if (selectedAd.adDestinationUrl && selectedAd.heroImageUrl) {
      ad.value = {
        slug: selectedAd.slug,
        imageUrl: selectedAd.heroImageUrl,
        linkUrl: selectedAd.adDestinationUrl,
      };

      setTimeout(() => {
        trackAdView();
      }, 500);
    }
  }
}

async function adClickTrack () {
  if (ad.value && ad.value.slug) {
    const insightData = {
      'action': 'click',
      'entity': 'Listing',
      'property': 'listing',
      'entitySlug': ad.value.slug,
      'displayedLocation': 'nh_hub_login',
      'eventData': {
        'userIntention': 'passive',
        'component': 'listing widget',
      },
    };

    await post('/public/analytics/track', insightData);
  }
}

async function trackAdView () {
  if (ad.value && ad.value.slug) {
    const insightData = {
      'action': 'view',
      'entity': 'Listing',
      'property': 'listing',
      'entitySlug': ad.value.slug,
      'displayedLocation': 'nh_hub_login',
      'eventData': {
        'userIntention': 'passive',
        'component': 'listing widget',
      },
    };

    await post('/public/analytics/track', insightData);
  }
}
</script>
<style lang="scss" scoped>
.clou-guest-layout {
  background: #f4f4ff;

  .ad-container {
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    padding: 0;

    .ad-tag {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      background: #ec6663;
      padding: 4px 12px;
      width: fit-content;
      height: fit-content;
      border-bottom-right-radius: 5px;
      box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.4705882353);
      color: #fff;
      font-size: 12px;
    }
  }

  .__guest-layout-container {
    padding: 0;

    .__guest-content-wrapper {
      height: 100vh;
    }
    .__guest-left-container {
      width: 100%;
      height: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      .__guest-left-container-bottom-street-wireframe {
        background: #727272;
        position: absolute;
        bottom: 5px;
        left: -3px;
        width: 100%;
        height: 80px;
        background: url('/images/backgrounds/street-wireframe.webp');
      }
    }

    .__main-wrapper {
      background: #fff;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;

      main {
        width: 100%;
        position: relative;
        max-height: 92%;
        height: 92%;
        overflow-y: auto;
        overflow-x: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        .v-row {
          max-height: 100%;
          overflow: auto;
        }

        .v-card {
          max-width: 540px;
        }
      }
    }
  }
}
</style>
